<template>
  <div class="page-main-marketing-results"
       :style="{maxWidth: maxWidth}">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12">
          <InteractiveTableHeader>
            <template #left>
              <slot name="headerLeft" />
            </template>
            <template #right>
              <slot name="headerRight" />
            </template>
          </InteractiveTableHeader>
          <Card>
            <template #content>
              <div class="m-3">
                <InteractiveTable :loading="loading"
                                  :columns="columns"
                                  :items="items"
                                  :meta="meta"
                                  :no-result-message="noResultMessage"
                                  :is-parent-marketing-card="true"
                                  @on-order-by="onOrderBy"
                                  v-if="items">
                  <template v-for="col in slotCols"
                            #[col.prop]="prop">
                    <slot :name="col.prop"
                          :row="prop.row" />
                  </template>
                </InteractiveTable>
                <div v-if="!items || items.length === 0"
                     class="no-result">
                  <div class="action">
                    <div class="button button--primary button--small ml-2"
                         @click="newSearch">
                      {{ t('marketingResults.buttons.newSearch') }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <Paginator v-if="meta.count > 10"
                     :rows="meta.perPage"
                     :total-records="meta.count"
                     :rows-per-page-options="perPage"
                     @page="pageChange">
            <template #end>
              <span v-if="perPage">
                {{ t('perPage.searchPerPage') }}
              </span>
            </template>
          </Paginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch } from "vue";
  import { useRouter } from "vue-router";
  import { t } from "@/logic";

  const props = defineProps([
    "columns", 
    "items",
    "meta",
    "maxWidth",
    "noResultMessage",
    "newSearchPath",
    "perPage",
    "loading"
  ]);

  const emit = defineEmits<{
    (e: "onOrder", order: any): void;
    (e: "pageChange", event: any): void;
  }>();

  const slotCols = computed(() => {
    return props.columns.filter((c) => c.type === "slot");
  });

  const router = useRouter();

  function onOrderBy(prop) {
    let direction = props.meta.order;
    let orderBy = props.meta.orderBy;
    console.log(orderBy);
    if (props.meta.orderBy === prop) {
      direction = direction === "asc" ? "desc" : "asc";
    } else {
      orderBy = prop;
    }

    emit("onOrder", {
      orderBy: orderBy,
      order: direction,
    });
  }

  function newSearch() {
    router.push({
      name: props.newSearchPath,
    });
  }

  async function pageChange(event) { 
    emit("pageChange", event);
  }
</script>

<style lang="scss" scoped>
.page-main-marketing-results {
  max-width: 120rem;
  margin: 1rem auto 0;
}

.no-result {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 50px 10px 50px 10px;
}

.no-result .message {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
</style>
